@media screen and (max-width: 400px) {
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer,
  #toppers,
  #features,
  #portfolio {
    width: 108% !important;
  }
}
