.float-call {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 120px;
  right: 40px;
  background-color: #fff;
  color: #608dfd;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float-call {
  margin-top: 16px;
}
